require('./bootstrap');


window.printDiv = function(divId) {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    mywindow.document.write('<html><head><link href="' + "{{ asset('css/app.css') }}" + ' rel="stylesheet"><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(divId).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(function(){ 
        mywindow.print(); mywindow.close();
    }, 1000);
    return true;
}


// Initialize color scheme
window.initializeColourScheme = function(htmlElement, userPreference, prefersDarkMode) {
    if (userPreference === 'dark') {
        htmlElement.classList.remove('system-preference');
        htmlElement.classList.add('dark-mode');
        document.getElementById('toggleColorScheme').style.backgroundColor = 'gold';
    } else if (userPreference === 'light') {
        htmlElement.classList.remove('system-preference');
        htmlElement.classList.add('light-mode');
        document.getElementById('toggleColorScheme').style.backgroundColor = 'white';
    } else {
        // Initially apply the appropriate class based on system preference
        if (prefersDarkMode) {
            htmlElement.classList.remove('system-preference');
            htmlElement.classList.add('dark-mode');
            document.getElementById('toggleColorScheme').style.backgroundColor = 'gold';
        } else {
            htmlElement.classList.remove('system-preference');
            htmlElement.classList.add('light-mode');
            document.getElementById('toggleColorScheme').style.backgroundColor = 'white';
        }
    }
}

// Toggle color scheme
window.changeColourScheme = function(htmlElement) {
    if (htmlElement.classList.contains('dark-mode')) {
        htmlElement.classList.remove('dark-mode');
        htmlElement.classList.add('light-mode');
        document.getElementById('toggleColorScheme').style.backgroundColor = 'white';
        // Save the user's preference when they toggle it
        localStorage.setItem('colorSchemePreference', 'light');
    } else {
        htmlElement.classList.remove('light-mode');
        htmlElement.classList.add('dark-mode');
        document.getElementById('toggleColorScheme').style.backgroundColor = 'gold';
        // Save the user's preference when they toggle it
        localStorage.setItem('colorSchemePreference', 'dark');
    }
}


window.onload = function() {
    // Dark mode functionality
    const toggleColorScheme = document.getElementById('toggleColorScheme');
    if (toggleColorScheme) {
        toggleColorScheme.addEventListener('click', function () {
            const htmlElement = document.documentElement;
            changeColourScheme(htmlElement);
        });
    }

    // Print funcationality
    const body = document.body;
    if (body) {
        body.addEventListener('click', function(event) {
            if (event.target.classList.contains('print')) {
                var printable_div = event.target.dataset.printableDiv;
                printDiv(printable_div);
            }
        });
    }
};
